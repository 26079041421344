import _ from 'lodash';

export const Client = {
  BETBIGGER: 'betbigger'
};

export const categorizeAvailableSports = availableSports => {
  let result = {};
  if (!availableSports) return;

  availableSports = availableSports?.map(item => {
    return {
      title: item?.name,
      league: item?.key,
      sport: item?.sport
    };
  });

  availableSports.forEach(sport => {
    const sportCategory = sport?.sport || '';

    if (!sportCategory) return;

    if (result[sportCategory]) {
      result[sportCategory].leagues.push(sport);
    } else {
      result[sportCategory] = {
        leagues: [sport],
        title: sportCategory
      };
    }
  });

  return result;
};

export const parseNumber = amount => {
  if (amount === undefined || isNaN(amount)) {
    return '0.00';
  }
  return amount.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true
  });
};

export function formatNumberForCurrency(value, currencySign = '$') {
  let newValue;
  if (typeof value === 'string') {
    newValue = parseFloat(value);
  } else if (typeof value === 'number') {
    newValue = value;
  }
  if (isNaN(newValue)) {
    return '-';
  } else if (newValue < 0) {
    const absoluteValue = Math.abs(newValue);
    return `-${currencySign}${parseNumber(absoluteValue)}`;
  } else {
    return `${currencySign}${parseNumber(newValue)}`;
  }
}

export const sortArrayByDateField = (array, field) => {
  return _.sortBy(array, tx => new Date(tx?.[field])).reverse();
};

export const getCurrentClient = () => {
  return process.env.REACT_APP_CLIENT;
};

export const getClientMetaData = () => {
  let metadata = {
    title: 'Sports Proptech'
  };

  const client = process.env.REACT_APP_CLIENT;

  switch (client) {
    case 'betbigger':
      metadata.title = 'Betbigger';
      break;
  }

  return metadata;
};
